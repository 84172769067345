<template>
  <div class="wrapper">
    <template v-if="loading">
      <v-skeleton-loader
        v-for="n in 12"
        :key="n"
        type="list-item"
      />
    </template>
    <template v-else-if="documents.length">
      <v-treeview
        ref="treeView"
        :active.sync="selected"
        :items="documents"
        open-all
        hoverable
        transition
        activatable
        open-on-click
        item-key="uniqueId"
        item-children="items"
        return-object
      >
        <template #prepend="{ item, open }">
          <v-icon v-if="item.isFolder">
            {{ open ? 'mdi-folder-open' : 'mdi-folder' }}
          </v-icon>
          <v-icon v-else :color="item.icon.color" :dark="item.icon.dark">
            {{ item.icon.code }}
          </v-icon>
        </template>
      </v-treeview>
    </template>
    <p
      v-else
      class="text-center mt-5"
    >
      Нет документов.
    </p>
    <cloud-document-dialog
      v-model="documentDialog"
      :doc-id="documentId"
      :view-url="viewUrl"
      :download-url="downloadUrl"
      :open-url="openUrl"
      :title="documentName"
      middle
    />
  </div>
</template>

<script>
import CloudDocumentDialog from '@/components/Document/CloudDocumentDialog'

export default {
  name: 'DocumentsTree',
  components: { CloudDocumentDialog },
  props: {
    documents: {
      type: Array,
      default: () => [],
      required: true
    },
    viewUrl: {
      type: String,
      default: '',
      required: true
    },
    downloadUrl: {
      type: String,
      default: ''
    },
    openUrl: {
      type: String,
      default: ''
    },
    loading: {
      type: Boolean,
      default: false
    }
  },
  data: () => ({
    selected: [],
    documentId: '',
    documentName: '',
    documentDialog: false
  }),
  mounted () {
    this.openAll()
  },
  watch: {
    documents () {
      this.openAll()
    },
    selected (val) {
      if ((val || []).length) {
        this.openDocumentDialog(val[0])
      }
    }
  },
  methods: {
    openAll () {
      this.$nextTick(() => {
        if (this.$refs.treeView) {
          this.$refs.treeView.updateAll(true)
        }
      })
    },
    openDocumentDialog (item) {
      this.documentId = item.uniqueId || ''
      this.documentName = item.name || ''
      this.documentDialog = true
    }
  }
}
</script>

<style scoped>
.wrapper {
  border-top: 1px solid #80808026;
}
.wrapper >>> .v-treeview > .v-treeview-node {
  border-bottom: 1px solid #80808026;
}
</style>
