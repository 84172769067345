<template>
  <v-row>
    <v-col
      cols="12"
      sm="10"
      offset-sm="1"
      md="8"
      offset-md="2"
    >
      <component
        :is="tree ? 'DocumentsTree' : 'DocumentsList'"
        :documents="documents || []"
        :loading="loading"
        :view-url="viewUrl"
        :download-url="downloadUrl"
        :open-url="openUrl"
      />
    </v-col>
  </v-row>
</template>

<script>
import DocumentsList from '@/components/Document/DocumentsList'
import DocumentsTree from '@/components/Document/DocumentsTree'

export default {
  name: 'DocumentsLoader',
  components: { DocumentsList, DocumentsTree },
  props: {
    url: {
      type: String,
      default: '',
      required: true
    },
    disabled: {
      type: Boolean,
      default: false
    },
    update: {
      type: Boolean,
      default: false
    },
    tree: {
      type: Boolean,
      default: false
    },
    filterId: {
      type: Number,
      default: 0
    }
  },
  data: () => ({
    loading: false,
    documents: [],

    viewUrl: '',
    thumbnailUrl: '',
    downloadUrl: '',
    openUrl: '',

    documentId: '',
    documentName: '',
    documentDialog: false
  }),
  watch: {
    update (val) {
      if (val) {
        this.loadDocuments()
      }
    }
  },
  mounted () {
    this.loadDocuments()
  },
  methods: {
    syncUpdate () {
      this.$emit('update:update', false)
    },
    loadDocuments () {
      if (this.disabled || this.loading) {
        this.syncUpdate()
        return
      }
      this.loading = true
      this.$axios.get(this.url).then(({ data }) => {
        this.viewUrl = data.url.view
        this.thumbnailUrl = data.url.thumbnail
        this.downloadUrl = data.url.download
        this.openUrl = data.url.open
        const documents = this.filterId
          ? (data.items || [])[this.filterId] || []
          : data.items || []
        const items = []
        documents.forEach((item) => {
          if (!item.items) {
            items.push(item)
            return
          }
          items.push({ divider: item.name, props: item.props })
          if (item.props.subtitle) {
            items.push({ subtitle: item.props.subtitle })
          }
          if (!item.items.length) {
            items.push({ empty: 'Нет документов' })
          } else {
            items.push(...item.items)
          }
        })
        this.documents = items
      }).catch(({ message }) => {
        this.documents = []
        this.$toasted.error(message)
      }).finally(() => {
        this.loading = false
        this.syncUpdate()
      })
    },
    openDocumentDialog (item) {
      this.documentId = item.uniqueId || ''
      this.documentName = item.name || ''
      this.documentDialog = true
    }
  }
}
</script>

<style scoped>

</style>
