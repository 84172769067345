<template>
  <div>
    <template v-if="loading">
      <v-skeleton-loader
        v-for="n in 12"
        :key="n"
        type="list-item"
      />
    </template>
    <template v-else-if="documents.length">
      <v-list>
        <template v-for="(item, index) in documents">
          <template v-if="item.divider">
            <v-subheader
              :key="item.divider"
              class="text-h6 mt-9 mb-3"
              :class="`${(item.props || {}).color || 'default'}--text`"
              style="height: auto;"
              v-text="item.divider"
            />
            <v-divider
              :key="item.divider + '_div'"
            />
          </template>
          <v-list-item
            v-else-if="item.empty"
            :key="item.empty"
          >
            <v-list-item-content>
              <v-list-item-title
                v-text="item.empty"
                class="font-wrap"
              />
            </v-list-item-content>
          </v-list-item>
          <v-list-item
            v-else-if="item.subtitle"
            :key="item.subtitle"
          >
            <v-list-item-content>
              <v-list-item-subtitle
                v-html="item.subtitle"
                class="font-wrap"
              />
            </v-list-item-content>
          </v-list-item>
          <v-list-item
            v-else
            :key="item.uniqueId"
            :class="{
              'ml-8': (documents[index] || {}).group,
              'mb-6': (documents[index] || {}).group && !(documents[index + 1] || {}).group
            }"
            @click="openDocumentDialog(item)"
          >
            <v-list-item-icon>
              <v-icon :color="item.icon.color" :dark="item.icon.dark">
                {{ item.icon.code }}
              </v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title
                v-text="item.name"
                class="font-wrap"
              />
            </v-list-item-content>
          </v-list-item>
        </template>
      </v-list>
    </template>
    <p
      v-else
      class="text-center mt-5"
    >
      Нет документов.
    </p>
    <cloud-document-dialog
      v-model="documentDialog"
      :doc-id="documentId"
      :view-url="viewUrl"
      :download-url="downloadUrl"
      :open-url="openUrl"
      :title="documentName"
      middle
    />
  </div>
</template>

<script>
import CloudDocumentDialog from '@/components/Document/CloudDocumentDialog'

export default {
  name: 'DocumentsList',
  components: { CloudDocumentDialog },
  props: {
    documents: {
      type: Array,
      default: () => [],
      required: true
    },
    viewUrl: {
      type: String,
      default: '',
      required: true
    },
    downloadUrl: {
      type: String,
      default: ''
    },
    openUrl: {
      type: String,
      default: ''
    },
    loading: {
      type: Boolean,
      default: false
    }
  },
  data: () => ({
    documentId: '',
    documentName: '',
    documentDialog: false
  }),
  methods: {
    openDocumentDialog (item) {
      this.documentId = item.uniqueId || ''
      this.documentName = item.name || ''
      this.documentDialog = true
    }
  }
}
</script>

<style scoped>

</style>
